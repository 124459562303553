.screen {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.container {
  width: 400px;
  max-width: 100%;
  padding: 20px 40px 30px;
}

.heading {
  //margin: 0;
}

.button {
  margin: 20px 0;
}

.info {
  margin: 0;
}
