.header {
  padding: 10px 0;
}

.avatar {
  width: 60px;
  height: 60px;
}

.avatarContainer {
  margin-right: 20px;
}
